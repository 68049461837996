<template>
<div class='main'>
  <div class="head">
    <ul>
      <li v-for="(item,index) in headWenzi" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.name}}</i>
      </li>
      <li><a @click="this.$router.push('/coffee/user')">会员</a></li>
      <li><a @click="this.$router.push('/coffee')">咖啡社区</a></li>
      <li><a @click="this.$router.push('/live')">直播</a></li>
    </ul>
  </div>
  <div class="body">
    <div class="body_cont">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item to="/coffee">咖啡社区</el-breadcrumb-item>
        <el-breadcrumb-item>行业品牌</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider />

      <div class="user">
        <img :src="detailList.memberIcon" alt="">
        <p>{{detailList.memberNickName}}</p>
        <!-- <span>关注他</span> -->
        <el-button @click="starTieZi('guanzhu')">
          {{whetherToPayAttention==0?'关 注':'取消关注'}}
        </el-button>
      </div>
      <p style="font-weight: bold;font-size: 20px;line-height: 59px;">
        {{detailList.title}}
      </p>
      <div class="text_title">
        <p class="time">发布于{{detailList.establishTime}}</p>
        <span>
          <el-icon><chat-dot-square /></el-icon>
        <strong>{{detailList.replies}}</strong>人评论</span>

        <!-- 未点赞 -->
        <span v-if="likeOrNot==0" @click="starTieZi('star')"><el-icon><magic-stick /></el-icon>点赞
        <strong>{{detailList.giveALike}}</strong></span>
        <!-- 已点赞 -->
        <span v-else @click="cancelStar('star')"><el-icon style="color:#D90000;"><magic-stick /></el-icon>取消点赞
        <strong>{{detailList.giveALike}}</strong></span>

        <!-- 未收藏 -->
        <span v-if="enshrine==0" @click="starTieZi('shoucang')"><el-icon><star /></el-icon>收藏</span>
        <!-- 已收藏 -->
        <span v-else @click="cancelStar('shoucang')"><el-icon><star-filled /></el-icon>取消收藏</span>
      </div>
      <!-- <el-divider /> -->
      <div class="text" v-html="allcont"></div>
      <div class="cont_img">
        <img  v-for="x in allpic" :key="x" :src="x">
      </div>
      <!-- <div class="text_foot">
        <p><span style="color: #333333;">文章来源：{{detailList.articleSource}}</span></p>
        <p>声明：易贸网力求信息真实、准确，文章提及内容仅供参考，不构成实质性贸易、投资建议，据此操作风险自担。</p>
      </div>
      <el-divider />
      <div class="text_foot">
        <div>
          <span class="guanjianci" style="color: #333333;">关键词：</span>
          <ul>
            <li v-for="i in guanjianciList" :key="i">{{i}}</li>
          </ul>
        </div>
      </div> -->
      <el-divider />
      <div class="comment" v-if="false">
         <el-input
          v-model="textarea"
          :autosize="{ minRows: 4 }"
          type="textarea"
          placeholder="文明上网，理性发言，请登录后发表评论……"
        />
        <ul>
          <!-- <div>
            <li>登录</li>
            <li>注册</li>
          </div> -->
          <div>
            <!-- <li>清除</li> -->
            <li @click="addPing">提交评论</li>
          </div>
        </ul>
      </div>

      <div class="discuss" v-if="false">
        <p>全部评论</p>
        <span>最新</span>
        <span>最热</span>
        <span>最早</span>
      </div>
      <el-divider v-if="false"/>
      <div class="discuss_cont" v-if="false">
        <ul>
          <li v-for="(i,k) in pingFatherList" :key="k">
            <div class="left">
              <img :src="i.memberIcon" alt="">
            </div>
            <!-- 父评论 -->
            <div class="right">
              <h3>
                <p>{{i.memberNickName}}</p>
                <span>{{i.createTime}}</span>
              </h3>
              <p>{{i.content}}</p>
              <div class="discuss_cont_div">
                <span @click="selectSonPing(i,k)">
                  <el-icon><chat-dot-square /></el-icon>评论
                </span>
                <span class="pingstar" v-if="i.likeOrNot==0"
                @click="pingFatherStar(i,k,'father')">
                  <el-icon><magic-stick /></el-icon>点赞
                  <strong style="color: #D90000;">{{i.giveALike}}</strong>
                </span>
                <span class="pingstar" v-else
                @click="pingFatherStar(i,k,'father')">
                  <el-icon><magic-stick /></el-icon>取消点赞
                  <strong style="color: #D90000;">{{i.giveALike}}</strong>
                </span>
              </div>
            </div>
            <!-- 子评论 -->
            <div class="bottom" v-show="i.haveSubComments!=0&&ziIndex==k"
              v-for="(item,index) in pingSonList" :key="index">
              <h3>
                <p>{{item.memberNickName}}：<span>{{item.content}}</span></p>
              </h3>
              <p>{{item.createTime}}</p>
              <div class="discuss_cont_div">
                <!-- 子评论回复 -->
                <!-- <span><el-icon><chat-dot-square /></el-icon>回复</span> -->
                <span v-if="item.likeOrNot==0" @click="pingFatherStar(item,index,'son')">
                  <el-icon><magic-stick /></el-icon>点赞
                  <strong style="color: #D90000;">{{item.giveALike}}</strong>
                </span>
                <span v-else @click="pingFatherStar(item,index,'son')">
                  <el-icon><magic-stick /></el-icon>取消点赞
                  <strong style="color: #D90000;">{{item.giveALike}}</strong>
                </span>
              </div>
            </div>
            <p class="zikan" v-if="i.haveSubComments!=0">
              <span class="xiala" @click="ziIndex=null" v-if="i.haveSubComments!=0&&ziIndex==k">
                <el-icon><arrow-up /></el-icon>收起回复
              </span>
              <span class="xiala" @click="chaPingSonList(i, k)" v-else>
                <el-icon><arrow-down /></el-icon>查看回复
              </span>
            </p>
            <!-- 子评论输入框 -->
            <div class="pingadd" v-show="isactPing==k">
              <el-input
                v-model="textareaSon"
                :autosize="{ minRows: 3 }"
                type="textarea"
                placeholder="文明上网，理性发言，请登录后发表评论……"
              />
              <el-button @click="addPingSon(i,k)" size="mini">提交评论</el-button>
            </div>
          </li>
        </ul>
      </div>
      <el-divider v-if="false"/>
      <div class="cont_btn">
        <!-- <el-button><el-icon><bottom /></el-icon>加载更多</el-button>
        <el-button>查看全部评论</el-button> -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'

const headWenzi = [
  { name: '首页', path: '/' },
  { name: '资讯', path: '/coffee/information' }
  // { name: '直播', path: '/coffee' },
  // { name: '咖啡社区', path: '/coffee' },
  // { name: '会员', path: '/coffee/user' }
]

// 获取帖子详情
const queryPostById = (data) => {
  return http.get('/mall-portal/mall/post/queryPostById', data)
}
// 帖子点赞或收藏 或关注发布者
const giveALikeAndEnshrine = (data) => {
  return http.post('/mall-portal/mall/post/giveALikeAndEnshrine', data, false)
}
// 取消关注发布者
const unfollowThePublisher = (data) => {
  return http.post('/mall-portal/mall/post/unfollowThePublisher', data)
}
// 取消点赞或收藏
const cancelGiveALikeAndEnshrine = (data) => {
  return http.post('/mall-portal/mall/post/cancelGiveALikeAndEnshrine', data, false)
}
// 评论点赞取消点赞
const forumCommentsLikeAndUnlike = (data) => {
  return http.post('/mall-portal/mall/post/forumCommentsLikeAndUnlike', data, false)
}
// 增加帖子评论
const postAComment = (data) => {
  return http.post('/mall-portal/mall/post/postAComment', data, false)
}
// 查询子评论
const querySubReviews = (data) => {
  return http.get('/mall-portal/mall/post/querySubReviews', data)
}

export default {
  name: 'CommunityArticle',
  data () {
    return {
      detailList: ref(''), // 帖子详情
      allcont: ref(''), // 帖子内容
      allpic: ref([]), // 帖子图片
      id: ref(''), // 帖子id
      likeOrNot: ref(''), // 帖子是否已点赞（0->未点赞，1->已点赞）
      enshrine: ref(''), // 帖子是否已收藏（0->未收藏，1->已收藏）
      whetherToPayAttention: ref(''), // 用户是否已关注（0->未关注，1->已关注）

      guanjianciList: ref(['咖啡']), // 帖子关键词list

      pingFatherItem: ref(''), // 用来保存当前点击的评论
      pingFatherList: ref([]), // 保存当前评论列表
      index: ref(''), // 当前评论数据的位置

      textarea: ref(''), // 文章父级评论框
      textareaSon: ref(''), // 文章子级评论框

      isactPing: ref(null), // 要展示的子评论输入框
      xialabtn: ref(true),
      ziIndex: ref(null),
      pingSonList: ref([]) // 当前评论列表子评论
    }
  },
  components: {},
  methods: {
    async initdataDetail (data) { // 初始化详情数据
      const loading = this.$loading({
        lock: true,
        text: '请稍候...'
      })
      // const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null
      const getData = {
        id: data,
        userId: userId ? userId.id : null
      }
      const res = await queryPostById(getData)
      console.log(res)
      if (res.data.code === 200) {
        this.detailList = res.data.data
        this.allcont = this.detailList.content.split('+-*/')[0]
        this.allpic = this.detailList.content.split('+-*/')[1].split(',')
        // console.log(this.allpic)
        this.likeOrNot = this.detailList.likeOrNot // 点赞状态
        this.enshrine = this.detailList.enshrine // 收藏状态
        this.whetherToPayAttention = this.detailList.whetherToPayAttention // 关注状态
        this.pingFatherList = this.detailList.postCommentEntities // 评论列表
      }
      loading.close()
    },
    async starTieZi (data) { // 帖子点赞收藏 关注发布者 功能触发
    // 点赞或者是收藏（0->点赞，1->收藏）
      let podata = {}
      if (data === 'star') { // 点赞
        podata = {
          postId: this.id,
          giveALikeMaybeEnshrine: 0
        }
        const res = await giveALikeAndEnshrine(podata)
        console.log(res)
        if (res.data.code === 200) {
          this.likeOrNot = 1
          this.detailList.giveALike += 1
        }
      } else if (data === 'shoucang') { // 收藏
        podata = {
          postId: this.id,
          giveALikeMaybeEnshrine: 1
        }
        const res = await giveALikeAndEnshrine(podata)
        console.log(res)
        if (res.data.code === 200) {
          this.enshrine = 1
        }
      } else { // 关注发布者
        if (this.whetherToPayAttention === 1) { // 取关
          podata = {
            postId: this.id
          }
          const res = await unfollowThePublisher(podata)
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({
              message: res.data.msg
            })
            this.whetherToPayAttention = 0
          }
        } else { // 关注
          podata = {
            postId: this.id,
            giveALikeMaybeEnshrine: 2
          }
          const res = await giveALikeAndEnshrine(podata)
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({
              message: res.data.msg
            })
            this.whetherToPayAttention = 1
          }
        }
      }
    },
    async cancelStar (data) { // 帖子取消点赞收藏
      let podata = {}
      if (data === 'star') { // 点赞
        podata = {
          postId: this.id,
          giveALikeMaybeEnshrine: 0
        }
        const res = await cancelGiveALikeAndEnshrine(podata)
        console.log(res)
        if (res.data.code === 200) {
          this.likeOrNot = 0
          this.detailList.giveALike -= 1
        }
      } else {
        podata = {
          postId: this.id,
          giveALikeMaybeEnshrine: 1
        }
        const res = await cancelGiveALikeAndEnshrine(podata)
        console.log(res)
        if (res.data.code === 200) {
          this.enshrine = 0
        }
      }
    },
    async pingFatherStar (item, index, status) { // 评论点赞取消点赞
      let data = {}
      if (item.likeOrNot === 0) {
        data = {
          LikeAndUnlike: 0,
          parentCommentId: item.id
        }
      } else {
        data = {
          LikeAndUnlike: 1,
          parentCommentId: item.id
        }
      }

      const res = await forumCommentsLikeAndUnlike(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
        if (status === 'father') { // 父评论
          if (this.pingFatherList[index].likeOrNot === 0) {
            this.pingFatherList[index].likeOrNot = 1
            this.pingFatherList[index].giveALike++
          } else {
            this.pingFatherList[index].likeOrNot = 0
            this.pingFatherList[index].giveALike--
          }
        } else {
          if (this.pingSonList[index].likeOrNot === 0) {
            this.pingSonList[index].likeOrNot = 1
            this.pingSonList[index].giveALike++
          } else {
            this.pingSonList[index].likeOrNot = 0
            this.pingSonList[index].giveALike--
          }
        }
      }
    },
    async addPing () { // 提交父级评论
      if (this.textarea !== '') {
        const data = {
          content: this.textarea,
          postId: this.id
        }
        const res = await postAComment(data)
        console.log(res)
        if (res.data.code === 200) {
          this.pingFatherList.unshift(res.data.data)
          ElMessage.success({
            message: '评论成功！'
          })
          window.location.reload() // 页面重新加载
        }
      } else {
        return ''
      }
    },
    selectSonPing (item, index) { // 控制要添加哪个评论
      this.isactPing = index
    },
    async addPingSon (item, index) { // 提交子级评论
      console.log(item)
      if (this.textareaSon !== '') {
        const data = {
          content: this.textareaSon,
          postId: this.id,
          parentCommentId: item.id
        }
        const res = await postAComment(data)
        console.log(res)
        if (res.data.code === 200) {
          this.pingFatherList[index].haveSubComments = 1
          ElMessage.success({
            message: '评论成功！'
          })
          this.isactPing = null
          this.pingSonList.unshift(res.data.data)
          this.pingSonList[0].likeOrNot = 0
        }
      } else {
        return ''
      }
    },
    async chaPingSonList (i, k) { // 获取帖子评论
      const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      const data = {
        userId: userId ? userId.id : null,
        parentCommentId: i.id
      }
      const res = await querySubReviews(data)
      console.log(res)
      if (res.data.code === 200) {
        this.pingSonList = res.data.data
        this.xialabtn = false
        this.ziIndex = k
      }
    }
  },
  mounted () {
    // console.log(this.$route.query.id)
    this.id = this.$route.query.id
    this.initdataDetail(this.$route.query.id)
  },
  setup (props, ctx) {
    return {
      headWenzi
    }
  }
}
</script>

<style lang='scss' scoped>
//@import ''; 引入公共css类
.main{
  width: 100%;
  background: #f2efed;
  // height: 800px;
  font-family: Microsoft YaHei;
  color: #333333;
  .head{
    height: 30px;
    background: #FFFFFF;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      font-size: 14px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        color: #333333;
        cursor: pointer;
        .na-text{
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
      li:nth-last-child(3),li:nth-last-child(2),li:last-child{
        float: right;
        a{
          color: #666;
        }
      }
    }
  }
  .body{
    width: 1200px;
    background: #FFFFFF;
    margin: 0 auto;
    margin-top: 20px;
    .body_cont{
      padding: 10px;
      // padding-top: 50px;
      .user{
        padding: 5px 0;
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          border-radius: 50%;
          height: 110px;
          width: 110px;
        }
        p{
          flex: 1;
          margin: 0 10px;
          font-size: 25px;
          color: #333333;
        }
        .el-button{
          width: 170px;
          height: 50px;
          border: 1px solid #1CC6FE;
          background: #4f2300;
          border: 1px solid #999999;
          color: #FFFFFF;
          font-size: 25px;
          border-radius: 15px;
          text-align: center;
        }
      }
      .text_title{
        display: flex;
        margin: 25px 0;
        font-size: 21px;
        color: #666666;
        .time{
          flex: 1;
        }
        span{
          // width: 150px;
          margin: 0 20px;
          display: flex;
          align-content: center;
          cursor: pointer;
          .el-icon{
            color: #edb200;
            font-size: 21px;
          }
          strong{
            color: #D90000;
          }
        }
      }
      .text{
        width: 100%;
        min-height: 200px;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 10px;
        line-height: 20px;
        // border: 1px solid #999999;
        border-top: 1px solid #999999;

      }
      .cont_img{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        height: 200px;
        border-bottom: 1px solid #999999;
        img{
          // flex: 1;
          width: 200px;
        }
      }
      .text_foot{
        margin-left: 50px;
        line-height: 50px;
        font-size: 21px;
        color: #999999;
        div{
          display: flex;
          ul{
            flex: 1;
            display: flex;
            li{
              width: 172px;
              background: #4f2300;
              border: 1px solid #999999;
              border-radius: 15px;
              text-align: center;
              color: #FFFFFF;
              margin: 0 10px;
            }
          }
        }
      }
      .comment{
        padding: 0 40px;
        ul{
          margin: 20px 0;
          display: flex;
          justify-content: flex-end;
          div{
            display: flex;
            // flex: 1;
            // width: 300px;
            li{
              cursor: pointer;
              flex: 1;
              width: 100px;
              font-size: 25px;
              font-weight: 400;
              color: #E6A506;
            }
          }
        }
      }
      .discuss{
        display: flex;
        align-items: center;
        padding: 20px 40px 0;
        p{
          flex: 1;
          font-size: 25px;
          color: #333333;
        }
        span{
          width: 80px;
          text-align: right;
        }
      }
      .discuss_cont{
        ul{
          li{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            .left{
              padding: 20px;
              img{
                height: 90px;
                width: 90px;
              }
            }
            .right{
              flex-shrink: 0;
              flex: 1;
              display: flex;
              flex-flow: column;
              line-height: 30px;
              padding: 10px 0;
              color: #333333;
              h3{
                display: flex;
                justify-content: space-between;
                p{
                  color: #007BED;
                }
              }
              p{
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .discuss_cont_div{
                display: flex;
                justify-content: flex-end;
                span{
                  // width: 80px;
                  cursor: pointer;
                  margin-left: 40px;
                  text-align: right;
                }
              }
            }
            .zikan{
              width: 100%;
              text-align: center;
              color: #EA6300;
              .xiala{
                cursor: pointer;
              }
            }
            .bottom{
              // align-items: right;
              box-sizing: border-box;
              width: 1050px;
              padding: 20px;
              background: #F2F2F2;
              display: flex;
              flex-flow: column;
              line-height: 30px;
              margin: 5px 0;
              // padding: 10px 0;
              color: #333333;
              h3{
                display: flex;
                justify-content: space-between;
                p{
                  color: #007BED;
                  span{
                    color: #666666 ;
                  }
                }
              }
              p{
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .discuss_cont_div{
                display: flex;
                justify-content: flex-end;
                span{
                  cursor: pointer;
                  // width: 80px;
                  text-align: right;
                }
              }
            }
            .pingadd{
              display: flex;
              flex-flow: column;
              width: 1050px;
              background: #F2F2F2;
              box-sizing: border-box;
              padding: 20px;
              align-items: flex-end;
              .el-button{
                margin-top: 10px;
                background: #E5A405;
                border-radius: 15px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
      .cont_btn{
        display: flex;
        justify-content: space-around;
        .el-button{
          width: 340px;
          height: 50px;
          background: #FFEAB7;
          border-radius: 25px;
          span{
            font-size: 25px;
            font-family: SimHei;
            font-weight: 400;
            color: #EA6300;
            i{
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
